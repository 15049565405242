import React, { useContext, useEffect } from "react";
import {
  Button,
  Card,
  Carousel,
  Col,
  Collapse,
  Form,
  Input,
  Row,
  message,
} from "antd";
import moment from "moment";
import { useHistory } from "react-router-dom";
import APIClient from "../../helpers/ApiClient";
import { TgContext } from "../../common/providers/TgProvider";
import CombinedBanner from "../CombinedBanner/CombinedBanner";
import { MailOutlined } from "@ant-design/icons";
import "../CombinedHome/CombinedHome.scss";
import CombinedOverallSearch from "../CombinedOverallSearch/CombinedOverallSearch";
import queryString from "query-string";
import Login from "../Login/LoginBan";
import Footer from "../../common/footer/Footer";

const { Panel } = Collapse;
const CombinedHome = (props) => {
  const [subsribeForm] = Form.useForm();
  let history = useHistory();
  const { updateTabKey } = useContext(TgContext);
  const oriDateString = "YYYY-MM-DD";
  const { updataCombineSearchObj, RemovePromo } = props.gscontext;
  const { combineSearchData } = props.gscontext.state;
  const subsribeData = {
    DeviceOS: 3,
    ApplicationType: 1,
    Email: "string",
    Status: 0,
    CreatedBy: 0,
    DeviceOSVersion: "string",
    DeviceToken: "string",
    FirBaseToken: "string",
  };
  console.log("CombinedHome");
  const handleSubscibe = (value) => {
    let data = {
      ...subsribeData,
      ...value,
    };
    APIClient.post("admin/subscriberReport", data)
      .then((response) => {
        if (response.status == 200) {
          message.success("Subscibed SuccessFully", 3);
          subsribeForm.resetFields();
        } else if (response.status == 400) {
          message.error("This Email Address Has Already Subscribed.", 3);
        } else if (response.status == 404) {
          message.error("Failed", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const topCities = [
    {
      origin: {
        airports: {
          airportCodeIATA: "BLR",
        },
        busStops: {
          Id: 109,
        },

        name: "Bangalore",
      },
      destination: {
        airports: {
          airportCodeIATA: "BOM",
        },
        busStops: [],

        name: "Mumbai",
      },
    },

    {
      origin: {
        airports: {
          airportCodeIATA: "BLR",
        },
        busStops: {
          Id: 109,
        },

        name: "Bangalore",
      },
      destination: {
        airports: {
          airportCodeIATA: "PNQ",
        },
        busStops: {
          Id: 114,
        },

        name: "Pune",
      },
    },

    {
      origin: {
        airports: {
          airportCodeIATA: "BLR",
        },
        busStops: {
          Id: 109,
        },

        name: "Bangalore",
      },
      destination: {
        airports: {
          airportCodeIATA: "HYD",
        },
        busStops: {
          Id: 100,
        },

        name: "Hyderabad",
      },
    },

    {
      origin: {
        airports: {
          airportCodeIATA: "HYD",
        },
        busStops: {
          Id: 100,
        },

        name: "Hyderabad",
      },
      destination: {
        airports: {
          airportCodeIATA: "BOM",
        },
        busStops: [],

        name: "Mumbai",
      },
    },
  ];

  const searchTopCities = (data, tabKey) => {
    let tomorrow = moment().add(1, "days").format("YYYY-MM-DD");
    updataCombineSearchObj("origin", data.origin);
    updataCombineSearchObj("destination", data.destination);
    updataCombineSearchObj("fromDate", tomorrow);
    updataCombineSearchObj("toDate", "");

    let query = {
      adult: 1,
      child: 0,
      class: "Economy",
      from: tomorrow,
      infant: 0,
      to: "",
      tripType: "oneWay",
      origin: `${data.origin.name}-${data.origin.airports.airportCodeIATA}-${data.origin.busStops.Id}`,
      destination: `${data.destination.name}-${data.destination.airports.airportCodeIATA}-${data.destination.busStops.Id}`,
    };
    updateTabKey(tabKey);

    let queryParams = queryString.stringify(query);
    history.push("/combinedresults?" + queryParams);
  };

  useEffect(() => {
    RemovePromo();
    updataCombineSearchObj("origin", []);
    updataCombineSearchObj("destination", []);
    updataCombineSearchObj("tripType", "oneWay");
    updataCombineSearchObj("classType", "Economy");

    if (combineSearchData)
      if (combineSearchData.fromDate) {
        if (
          moment(combineSearchData.fromDate, oriDateString) <
          moment().startOf("day")
        )
          updataCombineSearchObj("fromDate", moment().format(oriDateString));
      }
  }, []);

  return (
    <div className="combined-home">
      {/* <CombinedBanner /> */}
      {/* <div className="search-wrapper">
        <CombinedOverallSearch
          gscontext={props.gscontext}
          flightContext={props.flightContext}
          reset={true}
        />
      </div> */}
      {/* <div className="home-ban-im">
        <div className="image-container">
          <img
            className="homebann-im"
            src={require("../../assets/images/ban4.png")}
            alt="carousel-img"

          />
          {/* <h4>Search, Compare and Book Tickets </h4> *
        </div>
        <div className="log-im">
          <Login gscontext={props.gscontext} />
        </div>
      </div> */}

      <div className="home-content">
        <div className="home-ban-im">
          <div className="image-container">
            <img
              className="homebann-im"
              src={require("../../assets/images/ban4.png")}
              alt="carousel-img"
            />
          </div>
          <div className="log-im">
            <Login gscontext={props.gscontext} />
          </div>
        </div>
      </div>
      {/* <div className="footer-container">
        <Footer />
      </div> */}



      {/* <div className="content-wrapper">
        <div className="content-cards">
          <div className="site-card-wrapper">
            <Row>
              <Col lg={8} xs={24} className="cards-flex tarvel-22">
                <Card bordered={false} className="home-cards">
                  <img
                    src={require("../../assets/images/search_img.png")}
                    alt="search-img"
                  />
                  <h3>Search</h3>
                  <p>
                    Search with our easy to use interface & get diverse search
                    results to suit your travel needs
                  </p>
                </Card>
              </Col>
              <Col lg={8} xs={24} className="cards-flex tarvel-22">
                <Card bordered={false} className="home-cards">
                  <img
                    src={require("../../assets/images/compare.png")}
                    alt="compare-img"
                  />
                  <h3>Compare</h3>
                  <p>
                    Compare between different travel modes & different service
                    providers with a range of price & convenience options
                  </p>
                </Card>
              </Col>
              <Col lg={8} xs={24} className="cards-flex tarvel-22">
                <Card bordered={false} className="home-cards">
                  <img
                    src={require("../../assets/images/book_img.png")}
                    alt="book-img"
                  />
                  <h3>Book</h3>
                  <p>
                    Easy to book in few clicks with different modes of payment
                    which are completely safe & 100% secure
                  </p>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        {/* <div className="planning-block">
          <Row className="planning-block-row">
            <Col lg={10} xs={24} className="image-container"></Col>
            <Col lg={14} xs={24} className="planning-text planning-text-mobile">
              <h1>The eTravos Makes Travel Planning Easy</h1>
              <p>
                We’ll get you where you want to go. Our innovative app is the
                ideal way to plan and keep track of your travel.
              </p>
            </Col>
          </Row>
        </div> *
      </div> */}
      {/* <div className="adv-banner">
        <div className="adv-banner-container">
          <Row gutter={16} className="adv-row">
            <Col md={24} style={{display:"flex"}}>
              <div className="adv-text">
                <h3>The eTravos Makes Travel Affordable</h3>
                <p>
                  Compare your Bus options all in one search. We’ll
                  get you where you want to go. Find the best way to get from
                  anywhere in India, on your web. We bring larger
                  inventory from all sources to your finger tips.
                </p>
              </div>
              <div className="image-carousel">
              <img
              className="bus-bgimg"
                src={require("../../assets/images/bg-bus1.png")}
              />
              </div>
              {/* <div className="play-store-btns">
                <a href="https://play.google.com/store/apps/details?id=com.travel.trusgo" target="_blank">
                  <img
                    src={require("../../../src/assets/images/playstore.png")}
                    alt="playstore Icon"
                  />
                </a>
              </div> *
            </Col>
            {/* <Col md={12} className="image-carousel"> */}
      {/* <Carousel autoplay dots={false}> */}
      {/* <div>
                  <img
                    src={require("../../assets/images/MobileImages/trus-app-1.JPEG")}
                  />
                </div>
                <div>
                  <img
                    src={require("../../assets/images/MobileImages/trus-app-2.JPEG")}
                  />
                </div> */}
      {/* <div>
                  <img
                    src={require("../../assets/images/MobileImages/new-bus-3.JPEG")}
                  />
                </div> */}
      {/* </Carousel> */}



      {/* </Col> *
          </Row>
        </div>
      </div> */}

      {/* top-routes--open */}
      {/* <div className="top-routes new-top-all-routs">
        <div className="top-routes-container">
          <div className="top-bus-routes">
            <div className="top-bus-heading">
              <h4 className="bus-to-from">Top Bus Routes</h4>
            </div>
            <div className="top-bus-content">
              <ul className="buses-route-ul">
                {topCities.map((item, index) => (
                  <li key={index}>
                    <span onClick={() => searchTopCities(item, "2")}>
                      {item.origin.name} - {item.destination.name}
                    </span>
                  </li>
                ))}
                {/* <li>Hyderabad-Bangalore</li>
                    <li>Pune-Shirdi</li>
                    <li>Chennai-Coimbatore</li>
                    <li>Hyderabad-Visakhapatnam</li>
                    <li>Bangalore-Hyderabad</li>
                    <li>Bangalore-Vijayawada</li>
                    <li>Hyderabad-Chennai</li>
                    <li>Chennai-Bangalore</li>
                    <li>Bangalore-Chennai</li>
                    <li>Hyderabad-Vijayawada</li> *
              </ul>
            </div>
          </div>

          <div className="top-bus-op">
            <div className="top-trains-heading">
              <h4 className="bus-to-from">Top Bus Operators</h4>
            </div>
            <div className="top-trains-content">
              <ul className="buses-route-ul-top" style={{ display: "inline-grid" }}>
                {/* <li> Apsrtc</li>
                <li> Tsrtc</li>
                <li> Ksrtc</li> */}
      {/* <li>Gsrtc</li> */}
      {/* <li>Hrtc</li> */}
      {/* <li>Msrtc</li> */}
      {/* <li>VRL Travels</li> */}
      {/* <li>Intercity</li>
                <li>Orange Travels</li>
                <li>Morning Star Travels</li> */}
      {/* <li>KPN Travels </li> */}
      {/* <li>Kaveri Travels</li>
                <li>Kallada Travels</li>
              </ul>
            </div>
          </div> */}

      {/* <div className="top-flight-routes">
            <div className="top-trains-heading">
              <h4 className="bus-to-from">Top Searched Routes</h4>
            </div>
            <div className="top-flight-content">
              <ul className="buses-route-ul">
                {topCities.map((item, index) => (
                  <li key={index}>
                    <span onClick={() => searchTopCities(item, "1")}>
                      {item.origin.name} - {item.destination.name}
                    </span>
                  </li>
                ))}
                {/* <li> Delhi to Dubai </li>
                    <li> Delhi to Bangkok </li>
                    <li> Delhi to Toronto </li>
                    <li> Mumbai to Dubai </li>
                    <li> Delhi to Singapore </li>
                    <li> Delhi to London </li>
                    <li> Delhi to Kathmandu </li>
                    <li> Mumbai to London </li>
                    <li> Chennai to Singapore</li>
                    <li> Mumbai to Singapore </li> *
              </ul>
            </div>
          </div>
        </div>
      </div> *

      <div className="stay-tuned-block">
        <div className="stay-tuned-wrapper">
          <div>
            <h2>Stay Tuned</h2>
            <p>Get travel offers, trip reminders and other updates by email.</p>
          </div>


          <div className="staytuned-input">
            <Form form={subsribeForm} onFinish={handleSubscibe}>
              <Row gutter={16} justify="center">
                <Col md={6} sm={8} className="em" style={{ display: "contents" }}>
                  <Form.Item
                    name="Email"
                    rules={[
                      { required: true, message: "Required" },
                      { type: "email", message: "Please enter valid email" },
                    ]}
                  >
                    <Input
                      autocomplete="newpassword"
                      placeholder="Your Email Address"
                      prefix={<MailOutlined />}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col md={2} sm={4} className="sub-btn">
                  <Button
                    type="primary"
                    size={"large"}
                    className="tg-primary-btn"
                    htmlType="submit"
                  >
                    Subscribe
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div> */}

      {/* <div className="top-routes-mobile">
        <div className="top-routes-container-mobile">
          <Collapse className="bus-routes-collaspe" accordion>
            <Panel header="Top Bus Routes" key="1">
              <Row>
                <Col md={24} xs={24}>
                  <div className="top-bus-routes">
                    <div className="top-bus-content">
                      <ul className="buses-route-ul-mobile">
                        {topCities.map((item, index) => (
                          <li key={index}>
                            <span onClick={() => searchTopCities(item)}>
                              {item.origin.name} - {item.destination.name}
                            </span>
                          </li>
                        ))}
                       
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </Panel>

            <Panel header="Top Bus Operators" key="3">
              <div className="top-train-routes">
                <div className="top-trains-content">
                  <ul className="buses-route-ul-mobile">
                   
                    <li>VRL Travels</li>
                    <li>SRS Travels</li>
                    <li>Orange Travels</li>
                    <li>Morning Star Travels</li>
                    <li>KPN Travels </li>
                    <li>Kaveri Travels</li>
                    <li>Kallada Travels</li>
                  </ul>
                </div>
              </div>
            </Panel>

            <Panel header="Top Flight Routes" key="2">
              <div className="top-flight-routes">
                <div className="top-flight-content">
                  <ul className="buses-route-ul-mobile">
                    {topCities.map((item, index) => (
                      <li key={index}>
                        <span onClick={() => searchTopCities(item)}>
                          {item.origin.name} - {item.destination.name}
                        </span>
                      </li>
                    ))}
                    
                  </ul>
                </div>
              </div>
            </Panel>
          </Collapse>
        </div>
      </div> */}
      {/* <div>
        <Footer />
      </div> */}
    </div>
  );
};

export default CombinedHome;
