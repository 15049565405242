import React from "react";
// import CmsPages from "../../common/CMSPages/CmsPages";
import "./About.scss";
import rep from "../../assets/images/rep-icon.svg"
import ourteam from "../../assets/images/our-team.jpg"

const AboutUs = () => {
  // return <CmsPages cmsId={2} pageTitle="About Us" />;
  return (
    <div className="About-us">
      {/* <div className="hdr-img">
        <img width='100' src={bgImg} alt="Anjmal" />
      </div> */}
      <div className="About-us-page">
        <div className="hdr">
          <h2>About Us</h2>
        </div>
        <div className="container-box">
          <div className="col-md-8 col-xs-12 wb-padding textContainerStyle">
            <h2 className="heading-txt">
              Welcome to e<span style={{ color: "#f0802d", fontWeight: "600" }}>T</span>ravos
            </h2>
            <p className="paragraph">
              eTravos is India’s largest cloud Travel Technology Platform powering more than 300+ clients in India, Middle East and Europe .Our flagship product, eTravos can accommodate multiple global travel services. </p>
            <p className="paragraph">
              AI Based platform has master bus list from 5124+ bus operators Inventory through
              our flagship product eTravos API available in the market
            </p>
          </div>
          <div className="col-md-4 col-xs-12 image">
            <img src={rep} alt="Etravos" className="img-responsive" />
          </div>
        </div>
        <div style={{ padding: "2rem" }}></div>
        {/* <div className="wrapper-part" >
        <div className="content" >
          <h2 className="heading1">
            The destination<br />For your dream vacation
          </h2>
        </div>
      </div>
      <div style={{ padding: "2rem" }}></div> */}
        <div className="container1" >

          <center>

            <h2 className='heading2'>More about us</h2>

          </center>

          <div className='cont'>
            <img
              // src={ourteam}
              src={require("../../assets/images/testimonials-new.jpg")}
              alt="loading"
              className='imagestyle'
            />
            <p className="para">
              <span style={{ color: "#35459c", fontWeight: "600" }}>Bus API Overview</span><br />
              Our Fast, Easy, and Flexible Bus APIs connect you directly to all the necessary inventory required to build a travel website or app. This enables your customers to access excellent facilities on our Bus API from anywhere in the world.
              <span style={{ color: "#35459c", fontWeight: "600", marginTop: "15px" }}>Key Features of Our Bus API</span><br />
              <ul className="features-list">
                <li>
                  <strong>Extensive Operator Connectivity:</strong> 5124+ Operator's Bus Inventory.
                </li>
                <li>
                  <strong>Types of Buses:</strong> Volvo, Mercedes, AC Luxury, Deluxe, Standard, Sleeper, and other private buses.
                </li>
                <li>
                  <strong>Core Methods for Integration:</strong> Search, bus layout, block, book, get ticket, cancel confirmation, cancel, balance.
                </li>
                {/* <li>
                  <strong>Fast Staging Server for API Testing:</strong> Unlimited staging or test bookings through our API.
                </li> */}
                <li>
                  <strong>Instant Messaging:</strong> Receive instant messages about the bus, schedules, timetables, fare drops, discounts, and more.
                </li>
              </ul>
            </p>
          </div>
          {/* <div style={{ display: "flex", justifyContent: "space-between" }}>


            <div>

              <p className="para">
                <span style={{ color: "#35459c", fontWeight: "600" }}>Popular Road Transport Corporations (RTCs)</span>
              </p>
              <ul className="rtc-list">
                <li>Maharashtra State Road Transport Corporation (MSRTC)</li>
                <li>Himachal Road Transport Corporation (HRTC)</li>
                <li>Uttar Pradesh State Road Transport Corporation (UPSRTC)</li>
                <li>Odisha State Road Transport Corporation (OSRTC)</li>
                <li>PEPSU Road Transport Corporation (PEPSU)</li>
                <li>Jammu & Kashmir State Road Transport Corporation (JKSRTC)</li>
                <li>Rajasthan State Road Transport Corporation (RSRTC)</li>
                <li>Kadamba Transport Corporation Limited (KTCL)</li>
                <li>And many more.</li>
              </ul>
            </div>
            <div>
              <p className="para">
                <span style={{ color: "#35459c", fontWeight: "600" }}>Popular Bus Operators</span>
              </p>
              <ul className="operators-list">
                <li>Diwakar Travels</li>
                <li>Durgamba Motors</li>
                <li>Gujarat Travels</li>
                <li>Kallada Tours Travels</li>
                <li>Kesineni Travels</li>
                <li>Sharma Transport</li>
                <li>Orange Tours</li>
                <li>VRL Travels</li>
                <li>And several others.</li>
              </ul>
            </div>
            <div style={{ width: "20%" }}>
              <p className="para">
                <span style={{ color: "#35459c", fontWeight: "600" }}>Top Routes Served</span>
              </p>
              <ul className="routes-list">
                <li>Bangalore to Chennai, Coimbatore, Hyderabad, Mangalore, Mumbai, Pune, Vijayawada</li>
                <li>Delhi to Chandigarh</li>
                <li>Hyderabad to Chennai, Mumbai, Pune, Tirupati</li>
                <li>Mumbai to Goa, Shirdi</li>
                <li>Pune to Goa, Indore, Nagpur</li>
                <li>And more.</li>
              </ul>
            </div>
          </div> */}
          {/* <div>
            <div>
              <p className="para">
                <span style={{ color: "#35459c", fontWeight: "600" }}>Boarding and Dropping Points</span><br />
                City-wise boarding and dropping points for all bus operators. Clear boarding and dropping times are provided for the Multiple buses.
              </p> */}

          {/* <p className="para">
                <span style={{ color: "#35459c", fontWeight: "600" }}>Instant Cancellation, Refund, & Rebooking</span><br />
                Change of travel plans? Cancel bus tickets online with instant refunds or make alternate bookings. Our customer service representatives are ready to assist you with any queries regarding the bus booking process.
              </p> */}

          {/* </div>
          </div> */}
        </div>
        <div style={{ padding: "2rem" }}></div>
        <div className="container-contact">
          <h2 className="playfair">Contact us to know more</h2>
        </div>
        <div style={{ padding: "2rem" }}></div>
        <div className="Abrow mb-5" >
          <div className='sub-cont'>
            <h4 style={{ lineHeight: '1.5em' }}>

              Feel free to ask us<br />
            </h4>

            <p className="paragraph">Supercharge your business with eTravos <br />

              {/* Sign up now to experience the simplified API services with
              Quick Onboarding access to your dashboard */}
              </p>


          </div>
          <p className="btn-cont">
            <a href='/contact_us_form' className="btn btn-primary" style={{ textDecoration: "none", listStyle: 'none' }}>Contact Now</a>
          </p>
          {/* <div className='img-1'>
            <img className="img-fluid" src={require("../../assets/images/testimonials-new.jpg")} alt="" />
          </div> */}
          <p></p>
        </div>
        {/* <div className="footer-container" >
        <div className="row">
          <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12">
            <div className='feature'>
              <div className="col-md-3">
                <img src="https://www.anjmal.com/wp-content/uploads/2018/12/deck-chair-1.png" alt="" className='icon' />
              </div>
              <div className="col-md-9" style={{ paddingTop: '10px' }}>
                <h4><strong style={{ fontWeight: "600" }}>Top Destinations</strong></h4>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12">
            <div className='feature'>
              <div className="col-md-3">
                <img src="https://www.anjmal.com/wp-content/uploads/2018/12/wallet-1.png" alt="" className='icon' />
              </div>
              <div className="col-md-9" style={{ paddingTop: '10px' }}>
                <h4><strong style={{ fontWeight: '600' }}>The Best Prices</strong></h4>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12">
            <div className='feature'>
              <div className="col-md-3">
                <img src="https://www.anjmal.com/wp-content/uploads/2018/12/suitcase.png" alt="" className='icon' />
              </div>
              <div className="col-md-9" style={{ paddingTop: '10px' }}>
                <h4><strong style={{ fontWeight: '600' }}>Amazing Services</strong></h4>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div> */}
      </div>
    </div>
  );
};

export default AboutUs;
