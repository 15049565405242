// import React from "react";
// import CmsPage from "../CmsPage/CmsPage";
// import "./Refundpolicy.scss";
// const Refundpolicy = () => {
//   return <CmsPage id={11} />;
// };

// export default Refundpolicy;



import React, { useState, useEffect } from "react";
import * as ANTD from "antd";
import "./Refundpolicy.scss";
// import { Link } from "react-router-dom";
import { CaretRightOutlined } from "@ant-design/icons";
import CmsPage from "../CmsPage/CmsPage";
import APIClient from "../../helpers/ApiClient";
const Refundpolicy = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;

  const { Panel } = ANTD.Collapse;

  const [CMSList, setCMSList] = useState([]);
  // useEffect(() => {
  //   const getCMSList = () => {
  //     const CMSId = 11;
  //     APIClient.get("admin/cms/" + `${CMSId}`)
  //       .then((response) => {
  //         if (response.status == 200) {
  //           // console.log(
  //           //   "retur description:",
  //           //   response.data.pages.map((se) => {
  //           //     return se.Description;
  //           //   })
  //           // );
  //           setCMSList(response.data);
  //           // setDescription(response.data.pages.map(se=>{return se.Description}))
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   };
  //   getCMSList();
  // }, []);


  return (
    <div className="collapseCustomWhite">
      <div className="promo providerapi refundpolicy-new">
        <ANTD.Layout className="ourteam-bg">
          <div className="">
            <Content className="admin-container ">
              {/*  <ANTD.Row>
                                <ANTD.Col span={24}>
                                    <div className="">

                                        <div className="">
                                            <h2 className="common-headings">Our Team</h2>
                                            <p className="common-paras">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                                when an unknown printer took a galley of type and scrambled it to make a type 
                                                specimen book. It has survived not only five centuries, but also the leap into 
                                                electronic typesetting, remaining essentially unchanged. It was popularised in 
                                                the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                                                and more recently with desktop publishing software like Aldus PageMaker including 
                                                versions of Lorem Ipsum.</p>

                                        </div>

                                    </div>
                                </ANTD.Col>
                            </ANTD.Row> */}
            </Content>
          </div>
        </ANTD.Layout>
      </div>

      <ANTD.Layout className="ourteam-bg">
        <Content className="admin-container cms-pages-width">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <div className="ref-terms-po">
                <div className="">
                  <h2 className="common-headings">Refund Policy</h2>
                </div>
                <div className="ref-para">
                  <h1 style={{fontSize:"18px", fontWeight:"600"}}>Cancellation & Refund Policy</h1>
                  <p style={{ fontSize: "18px", fontWeight: "600", color: "#f0802d", marginTop:"10px" }}>
                    eTravos believes in helping its customers as far as
                    possible, and has therefore a liberal cancellation policy.<br/><span style={{color:"grey" , fontSize:"14px"}}> Under this policy:</span>
                  </p>
                  <p style={{fontSize:"14px", color:"black"}}>
                    {/* <span style={{ color: "#f0802d", fontWeight: "600", fontSize: "18px" }}>The use of this website or availing of our Services is subject to the following terms of use:</span> */}

                    <ul>
                      <li>• Cancellations will be considered only if the request is made immediately after placing the order. <br />
                        However, the cancellation request may not be entertained if the orders have been communicated to the
                        vendors/merchants and they have initiated the process of shipping them.</li>
                      <li>•

                        eTravos does not accept cancellation requests for
                        perishable items like flowers, eatables etc.<br /> However, refund/replacement can be made if the customer
                        establishes that the quality of product delivered is not good.</li>
                      <li>
                        • In case of receipt of damaged or defective items please report the same to our Customer Service team.<br />
                        The request will, however, be entertained once the merchant has checked and determined the same at his
                        own end. This should be reported within 7 Days days of receipt of the products. In case you feel that the
                        product received is not as shown on the site or as per your expectations, you must bring it to the notice of
                        our customer service within 7 Days days of receiving the product.<br /> The Customer Service Team after
                        looking into your complaint will take an appropriate decision.</li>

                      <li>• In case of complaints regarding products that come with a warranty from manufacturers, please refer
                        the issue to them. In case of any Refunds approved by the eTravos, it’ll take 9-15 Days days for the refund to be processed to the end customer.</li>
                    </ul>
                  </p>
                </div>

              </div>
            </ANTD.Col>
          </ANTD.Row>

          {/* <ANTD.Row>
            <ANTD.Col span={24}>
              <ANTD.Collapse
                bordered={false}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                className="site-collapse-custom-collapse"
                expandIconPosition="right"
              >
                {CMSList.map((res) => (
                  <Panel
                    header={res.Title}
                    key={res.Id}
                    className="site-collapse-custom-panel"
                  >
                    <div dangerouslySetInnerHTML={{ __html: res.Description }} />
                  </Panel>
                ))}
              </ANTD.Collapse>
            </ANTD.Col>
          </ANTD.Row> */}
        </Content>
      </ANTD.Layout>
    </div >
  );
};

export default Refundpolicy;
